import React from 'react'
import ReactDOM from 'react-dom'
import Loadable from 'react-loadable'

import { Provider } from 'mobx-react'

import ApplicationStore from './stores/ApplicationStore'
import App from './App'
import Loading from './Components/Loading'

let orgCode = ''
const baseUrl = process.env.BASE_DOMAIN
const authAppId = process.env.AUTH_APP_ID

// Get the orgCode
if (window.location.hostname !== baseUrl) {
  const subdomain = window.location.hostname
    .replace(new RegExp(`\\.s\\.${baseUrl}$`), '')
  if (subdomain) {
    orgCode = subdomain
  }
}

const stores = {
  app: new ApplicationStore(orgCode, baseUrl, authAppId)
}

let component
if (orgCode === '') {
  component = Loadable({
    loader: () => import('./App/Root'),
    loading: () => <Loading fullSize />
  })
} else {
  component = Loadable({
    loader: () => import('./App/Organization'),
    loading: () => <Loading fullSize />
  })
}

ReactDOM.hydrate(
  <Provider {...stores}>
    <App componentBase={component} />
  </Provider>,
  document.getElementById('app')
)
