import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { withStyles } from '@material-ui/core/styles'
import BeatLoader from 'react-spinners/BeatLoader'

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fullSize: {
    width: '100vw',
    height: '100vh'
  }
})

class Loading extends Component {
  static propTypes = {
    fullSize: PropTypes.bool.isRequired
  }

  static defaultProps = {
    fullSize: false
  }

  render () {
    const { classes, theme, fullSize } = this.props

    return (
      <div className={classnames(
        classes.root,
        {[classes.fullSize]: fullSize}
        )}>
        <BeatLoader
          size={30}
          color={theme.palette.secondary.main} />
      </div>
    )
  }
}

export default withStyles(styles, { withTheme: true })(Loading)
