import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    primary: {
      light: '#5ca0ff',
      main: '#0072ce',
      dark: '#00489c',
      contrastText: '#fff'
    },
    secondary: {
      light: '#56d6ca',
      main: '#00a499',
      dark: '#00746b',
      contrastText: '#000'
    }
  }
})
