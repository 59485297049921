import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import theme from '../themes'

import OAuthCallback from './Common/Pages/OAuthCallback'

class App extends Component {
  static propTypes = {
    /**
     * The base application, for root domain or for an organization
     */
    componentBase: PropTypes.func.isRequired
  }

  componentDidMount () {
    // Cleanup static styles and js
    document.getElementById('preload-css').remove()
  }

  render () {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Switch>
            <Route exact path='/oauth/callback' component={OAuthCallback} />
            <Route component={this.props.componentBase} />
          </Switch>
        </Router>
      </MuiThemeProvider>
    )
  }
}

export default App
