/* global URLSearchParams */
import React, { Component } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'

import { withRouter } from 'react-router-dom'
import { inject, observer } from 'mobx-react'

import Loading from '../../../Components/Loading'

@withRouter
@inject('app')
@observer
class OAuth extends Component {
  state = {
    error: false
  }

  componentDidMount () {
    const { app } = this.props
    const params = new URLSearchParams(window.location.search)
    const token = params.get('code')
    if (!token) {
      this.props.location.push('/')
      return
    }
    axios.post(
      `${app.apiUrl}/authorize`,
      {
        'token': token
      }
    ).then((res) => {
      const tokens = res.data
      app.setTokens(
        tokens.id_token,
        tokens.access_token,
        tokens.refresh_token
      )
      if (Cookies.get('organization')) {
        window.location.href = `https://${Cookies.get('organization')}.s.${app.baseUrl}`
      } else {
        this.props.history.push('/customer')
      }
    }).catch(() => {
      this.props.history.push('/')
    })
  }

  render () {
    return <Loading fullSize />
  }
}

export default OAuth
