import { observable, computed, action } from 'mobx'

import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'

class ApplicationStore {
  /**
   * The organization subdomain
   */
  organization = ''
  /**
   * The base url for all call
   */
  baseUrl = ''
  /**
   * The authentication application id
   */
  authAppId = ''
  /**
   * The identity token
   */
  @observable idToken
  /**
   * The access token
   */
  @observable accessToken
  /**
   * The refresh token
   */
  @observable refreshToken
  /**
   * The current api token for organization
   */
  @observable apiToken
  /**
   * The display name of the organization
   */
  @observable orgDisplayName
  /**
   * The user email
   */
  @observable userEmail

  constructor (organization, baseUrl, authAppId) {
    this.organization = organization
    this.baseUrl = baseUrl
    this.authAppId = authAppId
    this.idToken = Cookies.get('idToken') || null
    this.accessToken = Cookies.get('accessToken') || null
    this.refreshToken = Cookies.get('refreshToken') || null
  }

  @computed get isAuthenticated () {
    if (!this.accessToken) {
      return false
    }
    const information = jwtDecode(this.accessToken)
    if (!information || information.exp < Date.now() / 1000) {
      this.resetTokens()
      return false
    }
    /* Save the user email */
    const userInformation = jwtDecode(this.idToken)
    this.userEmail = userInformation.email
    return true
  }

  @computed get loginUrl () {
    return `https://auth.a.${this.baseUrl}/login?response_type=code&` +
      `client_id=${this.authAppId}` +
      `&redirect_uri=https:%2f%2f${this.baseUrl}/oauth/callback`
  }

  @computed get logoutUrl () {
    return `https://auth.a.${this.baseUrl}/logout?response_type=code&` +
      `client_id=${this.authAppId}` +
      `&redirect_uri=https:%2f%2f${this.baseUrl}`
  }

  @computed get apiUrl () {
    return `https://api.a.${this.baseUrl}`
  }

  @action setTokens (idToken, accessToken, refreshToken) {
    this.idToken = idToken
    this.accessToken = accessToken
    this.refreshToken = refreshToken

    Cookies.set(
      'idToken',
      idToken,
      { path: '/', domain: this.baseUrl }
    )
    Cookies.set(
      'accessToken',
      accessToken,
      { path: '/', domain: this.baseUrl }
    )
    Cookies.set(
      'refreshToken',
      refreshToken,
      { path: '/', domain: this.baseUrl }
    )
  }

  @action resetTokens (refresh = false) {
    this.idToken = null
    this.accessToken = null
    Cookies.remove('idToken', { path: '/', domain: this.baseUrl })
    Cookies.remove('accessToken', { path: '/', domain: this.baseUrl })
    if (refresh) {
      this.refreshToken = null
      Cookies.remove('refreshToken', { path: '/', domain: this.baseUrl })
    }
  }
}

export default ApplicationStore
